@font-face {
    font-family: 'Black';
    src: url('./Poppins/Poppins-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'BlackItalic';
    src: url('./Poppins/Poppins-BlackItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Bold';
    src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'BoldItalic';
    src: url('./Poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ExtraBold';
    src: url('./Poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ExtraBoldItalic';
    src: url('./Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ExtraLight';
    src: url('./Poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ExtraLightItalic';
    src: url('./Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Italic';
    src: url('./Poppins/Poppins-Italic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Light';
    src: url('./Poppins/Poppins-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'LightItalic';
    src: url('./Poppins/Poppins-LightItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Medium';
    src: url('./Poppins/Poppins-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'MediumItalic';
    src: url('./Poppins/Poppins-MediumItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Regular';
    src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'SemiBold';
    src: url('./Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'SemiBoldItalic';
    src: url('./Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Thin';
    src: url('./Poppins/Poppins-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ThinItalic';
    src: url('./Poppins/Poppins-ThinItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}