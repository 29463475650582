@import '../../Assets//Style/Variables/colors.scss';

.Subtitle {
    font-size: 3.2rem;
    font-family: "Bold";
    letter-spacing: 0px;
    line-height: 6rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.subtitle__color--secundary {
    color: $color-background;
}

@media (max-width: 768px) {
    .Subtitle {
        font-size: 2.2rem;
        text-align: center;
        line-height: 3rem;
    }
}