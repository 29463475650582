.FeatureItem {
    width: 85%;
}


.FeatureItem__head--title {
    font-size: 4rem;
    font-family: 'Bold';
    color: #003F72;
    line-height: 38px;
}

.FeatureItem__body-ul {
    margin-top: 2rem;
}

.FeatureItem__body-ul li {
    font-size: 3rem;
    font-family: 'Light';
    color: #707070;
    line-height: 38px;
    margin-bottom: 3rem;
}

.FeatureItem__body-ul li::marker {
    color: #1E9CD7;
}



@media (max-width: 992px),
screen and (orientation: portrait) {
    .FeatureItem {
        padding: 0 5rem;
        width: 100%;
    }

    .FeatureItem__head--title {
        font-size: 2rem;
        line-height: 25px;
    }

    .FeatureItem__body-ul li {
        font-size: 1.5rem;
        line-height: 20px;
        margin-bottom: 1.5rem;
    }
}