@import '../../Assets/Style/Variables/colors.scss';


.d-movile {
    display: none !important;
}

.d-pc {
    display: block;
}

.color-b1 {
    color: $color-letter;
}

.home--description {
    font-size: 2.4rem;
    color: #9E9E9E;
    font-family: 'Medium';
    text-align: center;
}




.home-intro {
    background: transparent linear-gradient(180deg, #FFFFFE 15%, #edf8fe 47%, #FFFFFF 86%) 0% 0% no-repeat padding-box;
    // height: 57rem;
}

.home-intro__content-img {
    // position: relative;
}

.home-intro-info {
    display: flex;
    align-items: center;
}

.home-intro--img {
    width: 100%;
}

.home-intro__p--description {
    font-size: 3rem;
    color: $color-background;
}

.home-offer {
    position: relative;
}

.home-offer>.col-12 {
    z-index: 12;
}

.home-offer__img-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Images/Home/Fondo_soluciones_1.png");
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-offer-content {
    margin-top: 5rem;
    display: grid;
    width: 100%;
    gap: 4rem 6rem;
    grid-template-columns: repeat(auto-fill, minmax(37rem, 1fr));
    justify-items: center;
    align-items: center;
    position: relative;
}

.home-offer-fondo {
    position: absolute;
    border: 2px solid #ccc;
    width: 70%;
    height: 68%;
    bottom: 10%;
}


//#region Content
.home-offer__service {
    position: relative;
    width: 40rem;
}

.home-offer__service img {
    width: 100%;
}

.home-offer__service-p {
    position: absolute;
    top: 15.5rem;
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.home-offer__service--text {
    font-size: 2.5rem;
    color: #003F72;
    text-align: center;
    font-family: 'Medium';
    line-height: 30px;
}

//#endregion

.home-trade {
    margin-top: 10rem !important;
    position: relative;
}

.home-trade>.col-12 {
    z-index: 12;
}

.home-trade__img-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Images/Home/Fondo_soluciones.png");
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-solutions {
    margin-top: 10rem !important;
    position: relative;
    height: 60rem;
}

.home-solutions__content-img {
    display: flex;
    justify-content: center;
}

.home-solutions--row {
    width: 90%;
    display: flex;
    height: 37rem;
}

.home-solutions>.col-12 {
    z-index: 12;
}

.home-solutions--img {
    position: absolute;
    top: 15rem;
    height: 44rem;
}

.home-solutions__img-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Images/Home/Fondo_soluciones.png");
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-solutions__content-text {
    position: relative;
    float: left;
    width: 50%;
    display: grid;
    align-content: space-between;
    justify-content: center;
}

.home--text {
    width: 60%;
    text-align: center;
    font-family: 'Regular';
    color: #707070;
    font-size: 3rem;
    line-height: 30px;
}

.home-solutions-item--1 {
    // position: absolute;
    top: -13rem;
    width: 100%;
    text-align: start;
}

.home-solutions-item--2 {
    // position: absolute;
    width: 100%;
    display: flex;
    top: 30px;
}

.home-solutions-item--3 {
    // position: absolute;
    bottom: -60px;
    width: 100%;
    text-align: start;
}

.home-solutions-item--4 {
    // position: absolute;
    top: -13rem;
    width: 100%;
    display: flex;
    justify-content: end;
}

.home-solutions-item--5 {
    // position: absolute;
    width: 100%;
    display: flex;
    justify-content: end;
    bottom: 7rem;
}

.home-solutions-item--6 {
    // position: absolute;
    width: 100%;
    display: flex;
    justify-content: end;
    bottom: -60px;
}

.home-ally {
    margin-top: 10rem !important;
    position: relative;
}

.home-ally>.col-12 {
    z-index: 12;
}

.home-ally__img-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Images/Home/Fondo_soluciones.png");
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-manage {
    margin-top: 10rem !important;
    position: relative;
}

.home-manage>.col-12 {
    z-index: 12;
}

.home-manage__img-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Images/Home/Fondo_soluciones.png");
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-iso__info {
    margin-top: 9rem;
    position: relative;
}

.home-iso__info-text {
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-iso__info--text {
    font-family: "Bold";
    font-size: 2.8rem;
    color: white;
    line-height: 39px;
    text-align: center;
}

@media (min-width: 1650px) {
    .th {
        display: flex;
        justify-content: center;
    }

    .home-offer-content {
        max-width: 1650px;
    }
}

@media (max-width: 1230px) {
    .home-offer-fondo {
        width: 53%;
        height: 80%;
        bottom: 5%;
    }

    .home-offer__item--d-none {
        display: none;
    }
}

@media (max-width: 1200px) {
    .home-offer {
        margin-top: 5rem !important;
    }

    .home-solutions--img {
        top: 17rem;
        height: 41rem;
    }

    .home-solutions-end .home--text {
        width: 40%;
    }

}

@media (max-width: 1250px) {
    .home--text {
        font-size: 2rem;
    }

    .home--text-start {
        justify-content: start;
    }

    .home--text-end {
        justify-content: end;
    }
}

@media (max-width: 992px) {
    .home-iso__info--text {
        font-size: 1.8rem;
        line-height: 28px;
    }
}

@media (max-width: 799px) {
    .home-offer-fondo {
        display: none;
    }
}

@media (max-width: 768px) {
    .home-intro__content-img {
        text-align: center;
    }

    .home-intro--img {
        width: 65%;
    }

    .d-pc {
        display: none;
    }

    .d-movile {
        display: block !important;
    }

    .color-movile {
        color: $color-letter;
    }

    .movile-tx-center {
        text-align: center;
    }

    .home-intro--title {
        display: flex;
        justify-content: center;
    }

    .home-intro--title .Title {
        width: 44rem;
    }

    .home-intro__p {
        display: flex;
        justify-content: center;
    }

    .home-intro__p--description {
        font-size: 2.1rem;
        text-align: center;
        width: 35rem;
    }

    .home-intro__button {
        text-align: center;
    }

    .home-offer {
        margin-top: 6rem !important;
    }

    .home-offer__p {
        display: flex;
        justify-content: center;
    }

    .home--description {
        width: 55rem;
        text-align: center;
        font-size: 2.2rem;
        font-family: 'Regular';
    }

    .home-offer-content--all {
        display: flex;
        justify-content: center;
    }

    .home-offer--img {
        width: 100%;
    }

    .home-solutions {
        height: auto;
    }

    .home--text-movile-start {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
    }

    .home--text-movile-end {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .home--text-movile {
        position: relative;
        width: 100%;
    }

    .home-text-span,
    .home-text-span-end {
        font-family: 'Medium';
        color: #003F72;
        font-size: 1.9rem;
    }

    .home-solutions--img-movile {
        width: 100%;
    }

    .home-text-span {
        position: absolute;
        width: 100%;
        text-align: end;
    }

    .home-text-1 .home-text-span {
        right: -40px;
    }

    .home-text-3 .home-text-span {
        right: -40px;
    }


    .home-text-span-end {
        position: absolute;
        width: 100%;
    }

    .home-text-4 .home-text-span-end {
        left: -40px;
    }

    .home-text-6 .home-text-span-end {
        left: -40px;
    }

    .home-iso__info {
        margin-top: 5rem;
    }

    .home-iso__info--text {
        font-size: 1.2rem;
        line-height: 18px;
    }
}

@media (max-width: 550px) {
    .home-intro--title .Title {
        width: 100%;
    }

    .home--description {
        width: 100%;
    }
}

@media (max-width: 400px) {

    .home-text-span,
    .home-text-span-end {
        font-size: 1.2rem;
    }

    .home-iso__info--text {
        font-size: 1rem;
        line-height: 12px;
    }

    .home-iso__info {
        margin-top: 2rem;
    }
}