.chatbot-container {
  width: 350px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatbot-header {
  background: #0078D4;
  color: white;
  padding: 12px;
  text-align: center;
  font-weight: bold;
}

.chatbot-messages {
  height: 400px;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  max-width: 80%;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
}

.message.bot {
  background: #0078D4;
  color: white;
  align-self: flex-start;
}

.message.user {
  background: #e0e0e0;
  color: black;
  align-self: flex-end;
}

.chatbot-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.chatbot-option {
  background: #005fa3;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s;

  &:hover {
    background: #003f7f;
  }
}

.chatbot-input {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px;
}

.chatbot-input input {
  flex: 1;
  padding: 8px;
  border: none;
  outline: none;
}

.chatbot-input button {
  background: #0078D4;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #005fa3;
  }
}

/* Animación de "Escribiendo..." */
.typing-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
  color: gray;
  font-size: 14px;
  margin-left: 10px;
}

.typing-indicator span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: gray;
  border-radius: 50%;
  animation: blink 1.5s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
}
