@import '../../Assets/Style/Variables/colors.scss';

// .resources-intro {}



.color-b1 {
    color: $color-letter;
}

.resources-intro-info {
    display: flex;
    align-items: center;
}

.resources-intro--title {
    display: flex;
    justify-content: center;
}

.resources-intro--title .Title {
    width: 44rem;
}

.Resources {
    padding: 0 1rem 0 3rem;
}
.resources-intro__p--text {
    font-size: 2rem;
    color: #1E9CD7;
    font-family: 'Bold';
    line-height: 29px;
}

.resources-intro__p {
    width: 90%;
}

.resources-intro__p--description {
    font-size: 3rem;
    color: #003F72;
    font-family: "Regular";
    line-height: 45px;
}

// .resources-intro__content-img {
//     // position: relative;
// }

.resources-intro--img {
    width: 100%;
}


.CardResours__p {
    position: absolute;
    bottom: 0;
    background-color: WHITE;
    width: 100%;
    padding-top: 1rem;
    text-align: center;
}

.CardResours__p--text {
    font-size: 2.5rem;
    font-family: 'Light';
    text-align: center;
    color: #707070;

}

.resources-caroucel {
    margin-top: 15rem;
    margin-bottom: 15rem;
}

.resources-magazine {
    margin-top: 15rem;
    margin-bottom: 15rem;
}

.CardImages-edition {
    width: 42rem;
    height: 39rem;
}

.CardImages-edition-img-back {
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Images/Recursos/Rectangulo_carrusel.png");
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.CardImages-edition-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CardImages-edition-img img {
    width: 80%;
}

.resources-intro-info {
    padding-left: 4rem;
}

.Resources .Title.title__color--secundary {
    font-size: 4rem;
}

@media (max-width: 1480px) {
    .Resources {
        padding: 0 1rem 0 3rem;
    }

}

@media (max-width: 992px),
screen and (orientation: portrait) {}

@media (max-width: 769px) {
    .resources-intro {
        display: flex;
        flex-direction: column-reverse;
    }

    .resources-intro__content-img {
        display: flex;
        justify-content: center;
    }

    .resources-intro--img {
        width: 80%;
    }

    .Resources .Title {
        font-size: 2.6rem !important;
    }

    .resources-intro__p--text {
        font-size: 1.5rem;
        text-align: center;
        line-height: 20px;
    }

    .resources-intro__p--description {
        font-size: 2rem;
        color: #9E9E9E;
        font-family: "Light";
        line-height: 28px;
        text-align: justify;
    }

    .CardImages-edition {
        width: 31rem;
        height: 29rem;
    }

    .resources-caroucel .content-Carousel {
        height: 318px !important;
    }
}

@media (max-width: 400px) {
    .resources-caroucel .content-Carousel {
        height: 290px !important;
    }

}

