img.powerapps-intro--img {
  width: 100%;
}

.powerapps-intro-info {
  display: flex;
  align-items: center;
  padding-left: 7rem !important;
  position: relative;
  z-index: 2;
}

.powerapps-intro__p p {
  font-size: 2.5rem;
}

img.fondo_intro {
  position: absolute;
  width: 100% !important;
}

.powerapps-intro__content-img {
  z-index: 2;
}

.color-subitlte-powerapps {
  color: #209dd7;
  font-size: 5.2rem !important;
}

.powerapps--description {
  font-size: 2.4rem;
  color: #9e9e9e;
  font-family: "Medium";
  text-align: start;
  margin-block-end: 7rem;
}

span.Text_color--secundary {
  color: #003f72;
  font-size: 2.5rem;
  font-family: "Bold";
  line-height: 1;
}

.powerapps-intro__button {
  margin-block-start: 7rem !important;
}

.powerapps-caracteristicas-info {
  padding: 0 6rem;
}

.Cards p.card-text {
  padding: 1rem 11% !important;
}

img.about-intro--img--mobile {
  display: none;
}

img.powerapps-caracteristica-img {
  width: 100%;
}
.automatizando_tareas_mobile {
  display: none;
}
.PowerApps img.que_ofrecemos {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}

.PowerApps .solutions_offer__content {
  position: relative;
  margin: -91px;
  display: flex;
  align-items: center;
}

.PowerApps .btn-solutions_offer--ticket {
  margin: 1rem!important;
}

.PowerApps .Footer {
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  img.powerapps-intro--img {
    display: none;
  }
  img.about-intro--img--mobile {
    display: block;
  }

  .row.powerapps-intro {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    align-content: center;
  }

  .color-subitlte-powerapps {
    color: #209dd7;
    font-size: 4rem !important;
  }

  span.color-subitlte-powerapps {
    font-size: 4rem !important;
  }

  span.color-subitlte-powerapps .Title.title__color--secundary {
    font-size: 4rem !important;
  }
  span.Text_color--secundary {
    display: none;
  }
  .powerapps-caracteristicas {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  p.powerapps--description {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 1rem;
    margin: 0 !important;
  }
  .cards-mision-vision {
    display: none;
  }
  .powerapps-intro-info {
    padding: 0 !important;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center !important;
  }
  .powerapps-intro-info p.home-intro__p--description {
    width: 100%;
    padding: 1rem;
  }
  .powerapps-intro-info .Title.title__color--secundary {
    padding: 1rem;
  }
  .powerapps-intro__button button.btn.button__prymary {
    width: 70%;
  }

  .PowerApps .swiper-slide img {
    display: block;
    width: 80%;
  }
}
