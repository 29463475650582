.teamviewer-intro-info {
  display: flex;
  align-items: center;
  padding-left: 8rem !important;
}

p.teamviewer-intro__p--description {
  color: #003f72;
  font-size: 2.5rem;
  font-family: "Medium";
}

img.teamviewer-intro--img {
  width: 100%;
}

.servicios_teamviewer {
  display: flex;
  justify-content: center;
}

img.teamviewer--img {
  width: 100%;
  margin-top: -10rem;
}

.TeamViewer .solutions_offer__content {
  margin-top: 0 !important;
  margin-block-end: -10rem !important;
  background: #003f72;
}

.TeamViewer .solutions_offer--title {
  color: white;
}

.TeamViewer .solutions_offer--text {
  color: white;
}

.TeamViewer .btn-solutions_offer--ticket {
  margin: 1rem 0!important;
}

img.teamviewer_mobile {
    display: none;
}

@media (max-width: 768px) {
  .TeamViewer .Title {
    text-align: start;
  }

  .teamviewer-intro {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  img.teamviewer--img {
    display: none;
  }

  img.teamviewer-intro--img {
    display: none;
  }

  .teamviewer__content-img {
    background: rgb(208, 242, 252);
    background: linear-gradient(
      0deg,
      rgba(208, 242, 252, 1) 20%,
      rgba(245, 252, 254, 1) 88%
    );
    height: 38rem;
  }

  img.teamviewer_mobile {
    width: 100%;
    display: block;
  }

  .servicios_teamviewer {
    margin-top: 1rem;
    margin-block-end: -12rem;
  }

  .TeamViewer .solutions_offer__content {
    display: none;
  }
}
