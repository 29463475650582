.CardImages {
    height: 100%;
    background-color: white;
}

.CardImages--img {
    height: 100%;
}

.CardImages--img img {
    height: 100%;
}



.CardImages-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

}

.CardImages-content:hover>.CardImages-content__info {
    display: block;
}

.CardImages-content__info {
    display: none;
    width: 100%;
    height: 100%;
}

.CardImages-content--back {
    background: #003F72 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 0.5;
    width: 100%;
    height: 100%;
}

.CardImages__p {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CardImages__p-text {
    width: 80%;
}

.CardImages-p--text {
    z-index: 9;
    color: white;
    font-size: 2.7rem;
    font-family: 'Medium';
    text-shadow: 0px 3px 6px #00000029;
    text-align: center;
    opacity: 1;
}



.CardImages-title {
    width: 100%;
    height: 6rem;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent linear-gradient(264deg, #1E9CD7 0%, #003F72 100%) 0% 0% no-repeat padding-box;
}

.CardImages-title--p {
    font-size: 5rem;
    color: white;
    font-family: 'SemiBold';
}