.about-intro__content-img {
  position: relative;
  width: 100%;
}

.about-intro-info {
  padding-block-start: 15%;
}

.about-intro--img {
  width: 100%;
}

.about-intro-info {
  /* position: absolute; */
  right: 1px;
  top: 50%;
  margin-block-start: 5%;
}

.cont-link-img {
  margin: 0;
}

.Title.title__color--secundary {
  color: #003f72;
}
.about-intro__p--description {
  font-size: 2.5rem;
  width: 91%;
}

.about-info {
  padding-left: 5%;
  margin-block-start: 7rem;
}

img.about-intro--img {
  position: absolute;
  width: 200%;
}

.about-intro-info {
  z-index: 2;
}
img.about-info-img {
  width: 90%;
}
.about-info .title__color--secundary {
  line-height: 1;
  font-size: 4rem;
  color: #003f72;
}
.col-12.mt-5.about__p p {
  color: var(--unnamed-color-707070);
}

.cards-mision-vision {
  margin-block-start: 4rem;
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  justify-content: center;
  position: relative !important;
}

.cards-mision-vision .Cards {
  width: 36rem;
}

.cards-mision-vision .Cards .card {
  height: 40rem;
  box-shadow: -1px 2px 2px #00000082;
}

.cards-mision-vision .Cards p.card-text {
  font-size: 1.8rem;
}

.cards-mision-vision img.search-img {
  width: 13rem;
}

img.img-fondo-vision {
  width: 100%;
}

.Nuestro.Equipo .Cards {
  cursor: pointer;
}

.secction-cards {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  justify-content: center;
  z-index: 1;
  margin-top: 1rem;
}

.fondo-mision-vision {
  position: absolute;
  top: 0px;
}
h5.card-title.vision {
  color: #003f72;
  font-weight: 700;
}
.especialistas .col-12.mt-5.about-intro__button {
  display: flex;
  justify-content: center;
  margin: 0 !important;
}

.Subtitle.subtitle__color--secundary-a.text-center {
  color: #9e9e9e;
  font-size: 2.5rem;
  font-family: "Regular";
}

.especialistas .Title.title__color--secundary {
  display: flex;
  justify-content: center;
  color: #1e9cd7;
  font-size: 4rem;
  margin: 30px 0px;
}
img.cards_casos--de--exito {
  width: 100%;
}
.tittle-casos-de-exito {
  display: flex;
  justify-content: center;
  margin: 6rem 0;
}
.top_10 {
  margin-top: 10rem !important;
}
.especialistas .col-12 {
  z-index: 2 !important;
}

.about-info {
  padding-left: 7rem !important;
  padding-top: 7rem !important;
}

.row.about-intro {
  height: 550px;
}

.color-subitlte {
  color: #209dd7;
  font-size: 2rem !important ;
  padding-right: 0.5rem;
  text-align: center;
  padding: 0 5px !important;
}

.Nuestro.Equipo .Cards .card .card-body p.card-text span {
  padding: 0 5px !important;
}
@media screen and (min-width: 1300px) {
  .mt-5 {
    margin-top: 0rem !important;
  }

  .about-intro-info {
    padding-right: 6rem;
  }
  .row.about-intro {
    height: 650px;
  }
  .about-intro-info {
    padding-block-start: 15%;
  }
  .Subtitle.subtitle__color--secundary-a.text-center {
    margin-top: 15rem !important;
    margin-block-end: 0 !important;
  }
  img.about-intro--img {
    width: 192% !important;
    height: 119% !important;
  }
}
.cards-mision-vision.a {
  position: relative;
  z-index: 1;
}

.casos_de_exito {
  position: relative;
  margin-top: 7rem;
}

.cards-casos--de--exito {
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 5rem;
  z-index: 2;
  position: relative;
}

img.cards_casos--de--exito {
  width: 100%;
  position: absolute;
  top: 18%;
}

.cards-casos--de--exito > .Cards:nth-child(2) .card {
  height: 42rem;
}

.cards-casos--de--exito .Cards .card {
  height: 37rem;
  width: 32rem;
}

.cards-casos--de--exito {
  display: flex;
  width: 100%;
  column-gap: 5rem;
  z-index: 2;
  position: relative;
  justify-content: center;
  align-items: center;
}

.cards-casos--de--exito > .Cards:nth-child(2) .card p.card-text {
  font-weight: 900;
}
.cards-casos--de--exito p.card-text {
  color: #003f72;
  font-size: 1.7rem !important;
  width: 36rem;
  text-align: center;
}
span.name-equipo-text1 {
  margin-top: 1rem;
  display: flex;
  font-weight: 900;
  justify-content: flex-start;
}

span.equipo-left-text1 {
  text-align: left !important;
  display: flex;
}

span.name-equipo-text2 {
  display: flex;
  margin-top: 3rem;
}

span.equipo-left-text2 {
  display: flex;
  font-weight: 100;
  text-align: left;
}

span.name-equipo-text {
  display: flex;
  margin-top: 8rem;
  font-weight: 900;
}

span.equipo-left-2 {
  display: flex;
}
.Nuestro.Equipo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 4rem;
}

.Nuestro.Equipo .Cards {
  width: 35rem !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.Nuestro.Equipo .Cards .card {
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: -1px 3px 2px #00000047 !important;
}

.Nuestro.Equipo .Cards .card .card-body {
  border-radius: 0 !important;
  height: 22rem;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}
img.img-nuestro-equipo {
  width: 100%;
}

.Nuestro.Equipo .Cards .card .card-body p.card-text {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}
.Nuestro.Equipo .Cards .card .card-body h5.card-title.classColorDefault {
  display: none;
}
.Nuestro.Equipo .Cards .card .card-body img.img-nuestro-equipo {
  width: 100%;
}

.Nuestro.Equipo .Cards .card .card-body .Title.title__color--secundary {
  display: flex;
  font-size: 2rem;
  justify-content: center;
  height: 4.3rem;
  gap: 4px;
}

.Nuestro.Equipo .Cards .card .card-body p.card-text span {
  padding: 0 !important;
  font-size: 1.5rem;
  text-align: center;
}
.Nuestro.Equipo .Cards .card:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.2);
  z-index: 2;
}
img.about-intro--img--mobile {
  display: none;
}
.row.resources-caroucel-mobile {
  display: none;
}

.Subtitle.subtitle__color--secundary-a.text-center {
  margin-top: 6rem !important;
  margin-block-end: 0 !important;
}
.tittle-casos-de-exito span.color-subitlte {
  font-size: 5.2rem !important;
}

.resources-caroucel-mobile_equipos {
  display: none !important;
}
@media screen and (min-width: 1024px) {
  .about-intro-info {
    padding-block-start: 9%;
  }
}

@media (max-width: 768px) {
  .about-intro--img {
    display: none;
  }
  .about-intro-info {
    padding-block-start: 0;
  }

  .resources-caroucel-mobile_equipos {
    display: flex !important;
  }

  .row.about-intro {
    height: 100%;
  }

  .about-intro-info .row .col-12 {
    padding-left: 5rem;
  }
  img.about-intro--img--mobile {
    width: 100%;
    display: block;
  }
  .about-intro-info {
    position: static !important;
  }
  .about-intro-info .color-movile {
    color: #003f72;
    display: flex;
    font-size: 5rem;
    padding-left: 2rem;
  }
  .cards-mision-vision.top_10 {
    margin-top: 6rem !important;
  }
  p.about-intro__p--description {
    text-align: left;
    font-size: 2rem !important;
    color: #003f72;
    font-weight: 600;
  }
  .about-intro-info button.btn.button__prymary {
    border-radius: 5px !important;
    width: 85%;
  }
  .row.about-automatizada {
    display: none;
  }
  .row.about-automatizada {
    display: none;
  }

  .cards-mision-vision {
    display: flex;
    flex-direction: column !important;
    gap: 13rem;
    align-items: center;
  }

  .casos_de_exito {
    display: none;
  }

  .swiper-slide.swiper-slide-visible.swiper-slide-active {
    display: flex;
    justify-content: center;
  }

  .swiper {
    padding-top: 0;
  }

  .swiper-slide img {
    display: block;
    width: 69% !important;
  }
  .row.resources-caroucel-mobile {
    display: block;
  }
  .top_10 {
    margin-top: 2rem !important;
  }
  .secction-cards {
    display: flex;
    flex-direction: column !important;
    gap: 9rem !important;
  }

  .equipos img {
    width: 80% !important;
  }

  .Nuestro.Equipo {
    display: none;
  }

  .About .Subtitle.subtitle__color--secundary-a.text-center {
    margin-block-end: 1rem !important;
  }
  .About .cards-mision-vision {
    display: flex !important;
  }
}
