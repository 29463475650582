@import '../../Assets/Style/Variables/colors.scss';



// $color-secundary-a
.Cards .card {
    background: #f1f1f1;
    box-shadow: -1px 3px 2px #00000047;
        border-radius: 15px;
}

.Cards .card-title {
    text-align: center;
    letter-spacing: 0px;
    font-size: 3rem;
    color: #1E9CD7;
    font-weight: 900;
}
.Cards p.card-text {
    color: #595958;
    padding: 1rem 16%;
    font-size: 1.5rem;
    font-weight: 400;
}

img.search-img {
    width: 10rem;
    position: relative;
}

.Cards .card-body {
    border-radius: 15px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: transparent linear-gradient(90deg, #FFFFFF 0%, #F2F2FF 100%) 0% 0% no-repeat padding-box;
}