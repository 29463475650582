@import '../../Assets/Style/Variables/colors.scss';

.contacto {
    background: transparent linear-gradient(180deg, #00BFD1 0%, #008FD4 100%) 0% 0% no-repeat padding-box;
    text-align: center;
    text-transform: uppercase;
    color: $color-FE !important;
    opacity: 1;
    width: 35rem;
    height: 63px;
    font-size: 2.2rem !important;
    font-family: 'Medium' !important;
    text-align: start !important;
    padding-left: 5rem !important;
}

.contacto.btn:hover {
    background: $color-background !important;
}

.contacto.btn:active {
    background: $color-background !important;
}

.button__prymary {
    background-color: white !important;
    color: $color-letter !important;
    height: 7rem !important;
    padding: 0 30px !important;
    font-size: 3rem !important;
    border: 3px solid $color-letter !important;
    border-radius: 14px !important;
    font-family: 'SemiBold' !important;
}


.button__prymary:hover {
    background-color: #003F72 !important;
    color: white !important;
    border: 4px solid $color-letter !important;
}

.button__prymary:active {
    background-color: #003F72 !important;
    color: white !important;
    border: 4px solid $color-letter !important;
}

@media (max-width: 768px) {
    .button__prymary {
        height: 2.8rem !important;
        font-size: 1.5rem !important;
        border: 1px solid $color-letter !important;
        border-radius: 1px !important;
        box-shadow: 0px 2px 4px 3px #eee;
    }
}