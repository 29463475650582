@import '../../Assets/Style/Variables/colors.scss';

.color-b1 {
    color: $color-letter;
}

.mtop-3 {
    margin-top: 3rem !important;
}



.mtop-5 {
    margin-top: 8rem !important;
}

.ptop-5 {
    padding-top: 9rem;
}

.Solutions-d_pc {
    display: block;
}

.Solutions-d_movile {
    display: none;
}


.solutions-intro-info {
    display: flex;
    align-items: center;
}

.solutions-intro--title .Title {
    width: 44rem;
}

.solutions-intro__p {
    width: 90%;
    margin-top: 5rem;
}

.solutions-intro__p--text {
    font-size: 2rem;
    color: #1E9CD7;
    font-family: 'Bold';
    line-height: 29px;
}

.solutions-intro__p--description {
    font-size: 3rem;
    color: #003F72;
    font-family: "Regular";
    line-height: 45px;
}

.solutions-intro--img {
    width: 100%;
}


.solutions-feature {
    margin-top: 10rem !important;
}

.feature_3 {
    width: 70%;
}

.solutions_feature_2 {
    text-align: end;
    margin-top: 7rem;
}

.solutions_feature_3 {
    text-align: end;
}

.solutions_feature_4 {
    display: flex;
    align-items: flex-end;
}

.solutions_feature_6 {
    text-align: center;
}

.solutions_feature_7 {
    display: flex;
    align-items: flex-end;
}



.mbottom-3 {
    margin-bottom: 6rem !important;
}

.solutions_offer__content {
    margin-top: 15rem !important;
    margin-bottom: 15rem;
}

.solutions_offer__info {
    display: flex;
    justify-content: flex-end;
}

.solutions_offer {
    width: 80%;
}

.solutions_offer--title {
    font-size: 6rem;
    color: #003F72;
    font-family: "Black";
}

.solutions_offer-description {
    margin-top: 2rem;
}

.solutions_offer--text {
    font-size: 2.5rem;
    color: #003F72;
    font-family: "Medium";
}

.solutions_offer-button {
    margin-top: 5rem;
}




.btn-solutions_offer {
    width: 35rem;
    border-radius: 35px !important;
    padding: 30px 0 0 0 !important;
    background: transparent linear-gradient(2deg, #2653C4 0%, #1E9CD7 100%) 0% 0% no-repeat padding-box;
}

.btn-solutions_offer--ticket {
    width: 35rem;
    border-radius: 35px !important;
    padding: 30px 0 0 0 !important;
    background: transparent linear-gradient(90deg, #FFFFFF 0%, #F2F2FF 100%) 0% 0% no-repeat padding-box;
}


.line-h {
    margin-top: 4rem;
}

.line-h p {
    line-height: 45px;
}

.solutions_offer__btn-number {
    font-size: 7rem;
    font-family: 'Black';
}

.solutions_offer__btn-text {
    font-size: 3rem;
    font-family: 'Medium';
}

.btn-hours-color {
    color: white;
}

.btn-ticket-color {
    color: #003F72;
}



@media (max-width: 769px) {
    .solutions-intro {
        display: flex;
        flex-direction: column-reverse;
    }

    .solutions-intro__content-img {
        text-align: center;
    }

    .solutions-intro--img {
        width: 80%;
    }

    .solutions-intro-info {
        display: block;
        margin-top: 5rem !important;

    }

    .btn-solutions_offer,
    .btn-solutions_offer--ticket {
        width: 80%;
    }
}

@media (max-width: 992px),
screen and (orientation: portrait) {
    .mtop-3 {
        margin-top: 2rem !important;
    }

    .mtop-5 {
        margin-top: 5rem !important;
    }

    .ptop-5 {
        padding-top: 4rem;
    }

    .Solutions-d_pc {
        display: none;
    }

    .Solutions-d_movile {
        display: block;
    }


    .solutions-intro--title .Title {
        text-align: start;
    }

    .solutions-intro__p--text {
        font-size: 1.4rem;
        line-height: 30px;
    }

    .solutions-intro__p--description {
        font-size: 1.9rem;
        line-height: 28px;
    }

    .solutions-intro__button {
        text-align: center;
    }


    .solutions_offer {
        width: 100%;
    }

    .solutions_offer__title-text {
        text-align: center;
    }

    .solutions_offer--title {
        font-size: 2rem;
    }

    .solutions_offer-description {
        padding: 0 4rem;
        text-align: center;
    }

    .solutions_offer--text {
        font-size: 1.5rem;
    }

    .solutions_offer-button {
        text-align: center !important;
        margin-top: 3rem;
    }

    .solutions_offer__btn-number {
        font-size: 4.5rem;
    }

    .solutions_offer__btn-text {
        font-size: 2rem;
    }

    .cont-btns {
        text-align: center;
    }

    .btn-solutions_offer {
        padding: 30px 0 15px 0 !important;
    }

    .btn-solutions_offer p {
        margin: 0 !important;
    }

    .btn-solutions_offer--ticket {
        padding: 30px 0 15px 0 !important;
    }

    .btn-solutions_offer--ticket p {
        margin: 0 !important;
    }

    .solutions_feature_1,
    .solutions_feature_6_movile,
    .solutions_feature_3,
    .solutions_feature_8,
    .solutions_feature_2_Movile {
        text-align: center;
    }

    .solutions_feature_4 {
        justify-content: center;
    }

    .feature_3 {
        width: 100%;
    }

    .solutions_offer__content {
        margin-top: 5rem !important;
        margin-bottom: 15rem;
    }

    .solutions_feature_6_movile img,
    .solutions_feature_4 img {
        height: 12rem;
    }

    .solutions_feature_1 img,
    .solutions_feature_8 img {
        height: 16rem;
    }
}