@import '../Font/Font.css';

html {
  font-size: 62.5%;
  /* =10px */

  font-family: Poppins;
}

body {
  overflow-x: hidden;
  font-family: 'Regular';
}

.d-movile {
  display: none !important;
}

// .d-pc {
//   display: block;
// }


@media (max-width: 768px) {
  .d-pc {
    display: none;
  }

  .d-movile {
    display: block !important;
  }
}