/* Contenedor principal */
.aviso-privacidad {
    font-family: "Arial", sans-serif;
    color: #2c3e50; /* Gris oscuro para legibilidad */
    line-height: 1.8; /* Mejor espaciado para texto */
    font-size: 1.3rem; /* Tamaño más grande para facilidad de lectura */
    max-width: 900px; /* Mayor ancho para acomodar más texto */
    margin: 2rem auto; /* Espaciado superior e inferior */
    padding: 2.5rem;
    background-color: #ffffff; /* Fondo blanco limpio */
    border: 1px solid #dcdcdc; /* Bordes suaves */
    border-radius: 12px; /* Bordes redondeados más suaves */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Sombra ligera y moderna */
  }
  
  /* Título principal */
  .aviso-privacidad h1 {
    font-size: 2.5rem; /* Tamaño grande para destacar el título */
    color: #007acc; /* Azul vibrante */
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: uppercase; /* Mayúsculas para destacar */
    letter-spacing: 0.05rem; /* Espaciado ligero entre letras */
  }
  
  /* Subtítulos */
  .aviso-privacidad h2 {
    font-size: 1.8rem; /* Más pequeño que el título principal */
    color: #34495e; /* Azul grisáceo */
    font-weight: bold;
    margin-top: 2rem; /* Espaciado superior */
    margin-bottom: 1rem; /* Espaciado inferior */
    border-left: 4px solid #007acc; /* Línea decorativa */
    padding-left: 1rem; /* Espacio interno al borde */
  }
  
  /* Subtítulos secundarios */
  .aviso-privacidad h3 {
    font-size: 1.5rem;
    color: #7f8c8d; /* Gris claro */
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  /* Párrafos */
  .aviso-privacidad p {
    margin-bottom: 1.5rem;
    text-align: justify; /* Justificado para mejor legibilidad */
    font-size: 1.4rem; /* Texto un poco más grande */
  }
  
  /* Listas */
  .aviso-privacidad ul {
    margin: 1rem 0 1.5rem 1.5rem; /* Margen superior e indentación */
    padding-left: 1.5rem;
  }
  
  .aviso-privacidad li {
    margin-bottom: 0.8rem;
    font-size: 1.3rem; /* Aumenta el tamaño de los ítems */
    color: #2c3e50; /* Gris oscuro */
  }
  
  /* Enlaces */
  .aviso-privacidad a {
    color: #007acc; /* Azul vibrante */
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease; /* Efecto de transición al pasar el ratón */
  }
  
  .aviso-privacidad a:hover {
    color: #0056b3; /* Azul más oscuro en hover */
    text-decoration: none;
  }
  
  /* Separación visual entre secciones */
  .aviso-privacidad hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid #e0e0e0; /* Línea suave */
  }
  
  /* Efecto visual */
  .aviso-privacidad {
    animation: fadeIn 0.5s ease-in-out; /* Animación de entrada */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  