@import '../../Assets//Style/Variables/colors.scss';

.Title {
    font-size: 5.2rem;
    font-family: "Bold";
    letter-spacing: 0px;
    line-height: 6rem;
}

.title__color--secundary {
    color: $color-background;
}

@media (max-width: 768px) {
    .Title {
        font-size: 2.5rem;
        text-align: center;
        line-height: 4rem;
    }
}