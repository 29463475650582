.main {
    margin: 0 auto;
    overflow: hidden;
    padding: 2rem 0rem 1rem ;
}

.main-home {
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: rgb(255, 255, 255);
}


@media (max-width: 992px),
screen and (orientation: portrait) {
    .main {
        max-width: 1480px;
        margin: 0 auto;
        overflow: hidden;
    }
}