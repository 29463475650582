.head-nav-button {
    width: 50px;
    height: 45px;
    margin-left: 1rem;
}

.head-nav__button--call {
    border: 1px solid #1E9CD7 !important;
    border-radius: 5px !important;
}

.head-nav__button--menu {
    background-color: #1E9CD7 !important;
    color: white;
    border: 0px !important;
    border-radius: 5px !important;
}

.nav-link-text .nav-item {
    margin-right: 3rem;
}

.cont-link-img {
    margin: 2rem 3rem 0rem 5rem;
}

.cont-link-img img {
    height: 15rem;
}

.nav-link-text {
    margin-left: auto;
}

.nav-link-text .dropdown-toggle::after {
    color: #c2c2c2;
}

.nav-link-text .nav-link {
    font-size: 2.5rem;
    font-family: 'Light';
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.header-nav--items .dropdown-item {
    font-size: 2rem;
}

@media (max-width: 1200px),
screen and (orientation: portrait) {
    .cont-link-img img {
        height: 12rem;
    }
}

@media (max-width: 992px),
screen and (orientation: portrait) {
    .cont-link-img img {
        height: 8rem;
    }

    .button-menu-svg svg {
        color: white;
    }
}