@import "../../Assets/Style/Variables/colors.scss";

.Footer {
  margin-top: 10rem;
  width: 100%;
  height: 300px;

  .container-footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 209px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .section-down {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 1.4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background: transparent linear-gradient(90deg, #1e9cd7 0%, #003f72 100%) 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 3px #00000029;
  }

  .text-address {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 295px;
      font-size: 1.3rem !important;
      color: white;
      line-height: 15px;
      font-family: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15) / var(--unnamed-line-spacing-22) var(--unnamed-font-family-poppins);
    }
  }

  .call-me a {
    font-size: 2.5rem;
    color: white !important;
    font-family: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 35px/53px var(--unnamed-font-family-poppins);
    text-decoration: none;
  }

  .call-me {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .logos {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      text-decoration: none;
    }

    hr {
      height: 100%;
      width: 3px;
      background: #ffff;
      color: #ffff;
    }

    .bi-instagram::before,
    .bi-linkedin::before,
    .bi-twitter::before,
    .bi-youtube::before {
      font-size: 28px;
      margin: 0 15px;
      background: white;
      color: #0b6197;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      cursor: pointer;

      &.bi-facebook::before {
        font-size: 45px;
        color: white;
        padding: 0px 15px;
        cursor: pointer;
      }

      &.bi-twitter::before,
      &.bi-linkedin::before {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }

    .bi-facebook::before {
      font-size: 45px;
      color: white;
      padding: 0px 15px;
      cursor: pointer;
    }
  }

  .section-up {
    display: flex;
    flex-direction: row;
    background: transparent linear-gradient(180deg,
        rgba(255, 255, 255, 0.5803921569) 0%,
        #d3d3d36e 100%) 0% 0% no-repeat padding-box;
    align-items: center;
  }

  .section-up .card {
    height: 170px;
    border: none;
    background: none;
  }

  .card.card-conecctive {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0px 15px 15px 0;
    display: flex;
    flex-direction: row;
  }

  img.contactanos-img {
    width: 300px;
    cursor: pointer;
  }

  ul.nav.flex-column li a {
    font-size: 1.6rem;
    color: #003f72;
    font-weight: 900;
  }

  a.nav-link.active.color-blue {
    color: #1e9cd7 !important;
  }

  .navbar-footer hr {
    height: 140px;
    width: 3px;
    background: transparent linear-gradient(177deg, #1e9cd7 0%, #003f72 100%) 0% 0% no-repeat padding-box;
    border-radius: 100px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .card.card-conecctive .card-body {
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cont-link-footer-img img {
    height: 21rem;
  }

  h5.card-title {
    color: $color-background;
    font-size: 1.7rem;
    font-weight: bold;
  }

  .card-subtitle {
    color: $color-background;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
  }

  a.card-link {
    color: $color-background;
    font-size: 1.3rem;
    text-decoration: none;
    margin: 0;
  }

  .card-body.politicas {
    gap: 19px;
  }

  @media only screen and (max-width: 600px) {
    .container-footer {
      display: flex !important;
      flex-direction: column;
    }

    .navbar-footer {
      height: 98px;
    }

    .navbar-footer hr {
      height: 92px;
    }

    nav.col-md-2.d-none.d-md-block {
      display: block !important;
    }

    span.text-adress-span {
      display: none;
    }

    .cont-link-footer-img img {
      height: 6rem;
    }

    .card {
      display: none;
    }

    img.contactanos-img {
      width: 15rem !important;
    }

    ul.nav.flex-column li a {
      font-size: 1rem !important;
    }

    .card.card-conecctive {
      max-width: 42rem;
    }

    .section-down {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      height: 38px;
    }

    .section-up {
      height: 130px;
    }

    .call-me a {
      font-size: 2rem !important;
      text-align: center;
    }

    .logos {
      margin-block-start: 14px;
    }

    .logos hr {
      display: none;
    }

    .logos .bi-facebook::before,
    .logos .bi-instagram::before,
    .logos .bi-linkedin::before,
    .logos .bi-twitter::before,
    .logos .bi-youtube::before {
      background: transparent linear-gradient(90deg, #1e9cd7 0%, #003f72 100%) 0% 0% no-repeat padding-box;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1607843137);
      color: #ffff;
    }

    .logos .bi-facebook::before {
      padding: 0;
      border-radius: 50% !important;
      font-size: 28px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: 376px) {
      .card.card-conecctive {
        max-width: 37rem;
        padding: 0;
      }

      img.contactanos-img {
        width: 12rem !important;
      }

      .cont-link-footer-img img {
        height: 5rem;
      }
    }

    @media only screen and (max-width: 320px) {
      .card.card-conecctive {
        max-width: 31rem;
      }

      img.contactanos-img {
        width: 9rem !important;
      }

      .cont-link-footer-img img {
        height: 4rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Footer {
    height: 240px !important;
  }
}