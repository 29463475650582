/* Contenedor principal */
.preguntas-frecuentes {
    font-family: "Arial", sans-serif;
    color: #2c3e50; /* Texto gris oscuro */
    line-height: 1.8; 
    font-size: 1.2rem; /* Tamaño cómodo para leer */
    max-width: 900px; 
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff; 
    border-radius: 12px; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  /* Título principal */
  .preguntas-frecuentes h1 {
    font-size: 2.5rem; 
    color: #007acc; /* Azul vibrante */
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem; 
  }
  
  /* Categorías */
  .preguntas-frecuentes h2 {
    font-size: 1.8rem; 
    color: #34495e; /* Azul grisáceo */
    font-weight: bold;
    margin-top: 2rem; 
    margin-bottom: 1rem; 
    border-left: 4px solid #007acc; 
    padding-left: 1rem; 
  }
  
  /* Preguntas */
  .preguntas-frecuentes .pregunta {
    margin: 1.5rem 0;
    font-weight: bold;
    font-size: 1.4rem; 
    cursor: pointer; /* Cursor para indicar interactividad */
    position: relative;
  }
  
  .preguntas-frecuentes .pregunta::after {
    content: "+";
    font-size: 1.5rem;
    color: #007acc;
    position: absolute;
    right: 0;
    transition: transform 0.3s ease;
  }
  
  .preguntas-frecuentes .pregunta.abierta::after {
    content: "-";
    transform: rotate(180deg);
  }
  
  /* Respuestas */
  .preguntas-frecuentes .respuesta {
    margin-top: 0.5rem;
    padding-left: 1.5rem; 
    font-size: 1.2rem; 
    color: #4a4a4a; 
    display: none; /* Oculto por defecto */
  }
  
  .preguntas-frecuentes .pregunta.abierta + .respuesta {
    display: block; /* Mostrar respuesta cuando la pregunta está abierta */
  }
  
  /* Separación entre categorías */
  .preguntas-frecuentes hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid #e0e0e0; 
  }
  
  /* Animación para respuestas */
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 500px;
      opacity: 1;
    }
  }
  
  .respuesta {
    animation: slideDown 0.3s ease;
  }
  