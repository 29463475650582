.CarouselBoostrap .carousel-indicators {
    bottom: -50px;
}

.CarouselBoostrap .carousel-indicators [data-bs-target] {
    height: 15px;
    background-color: #1E9CD7;
}

.CarouselBoostrap .carousel-control-next,
.CarouselBoostrap .carousel-control-prev {
    background-color: #3569E3;
    height: 11rem;
    width: 11rem;
    top: 20rem;
    opacity: .3;
}

.CarouselBoostrap .carousel-control-next:focus,
.CarouselBoostrap .carousel-control-next:hover,
.CarouselBoostrap .carousel-control-prev:focus,
.CarouselBoostrap .carousel-control-prev:hover {
    opacity: .3;
}

.CarouselBoostrap .carousel-control-next-icon,
.CarouselBoostrap .carousel-control-prev-icon {
    width: 8rem;
    height: 6rem;
}