.content-caroucel-item {
    display: flex;
    justify-content: center;
}

.content-caroucel__item {
    width: 85%;
    display: flex;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
}

.bg-color-caroucel {
    background-color: #E6E9EC;
}

.bg-color-caroucel_2 {
    background-color: #F4F2F5;
}

.content-caroucel__item-img-e {
    position: relative;
}

.content-caroucel__item-img-s {
    position: relative;
}

.content-caroucel_img-envolent {
    position: absolute;
    top: 68px;
    right: -47px;
    width: 30%;
    z-index: 1;
}

.content-caroucel__item-info-col {
    position: absolute;
    z-index: 46;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.content-caroucel__item-info .Subtitle {
    margin-top: 0rem;
    margin-bottom: 3rem;
}

.content-caroucel__item-info {
    width: 80%;
}

.content-caroucel__item-info--text {
    color: #003F72;
    font-family: 'Regular';
    font-size: 2rem;
    line-height: 35px;
}


@media (max-width: 1200px) {

    .content-caroucel__item-info .Subtitle {
        font-size: 2.8rem;
    }

    .content-caroucel__item-info--text {
        font-size: 1.8rem;
        line-height: 26px;
    }

    .CarouselBoostrap .carousel-control-next-icon,
    .CarouselBoostrap .carousel-control-prev-icon {
        width: 8rem;
        height: 6rem;
    }
}

@media (max-width: 992px) {

    .content-caroucel__item {
        display: flex;
        flex-direction: column;
    }

    .content-caroucel__item-info .Subtitle {
        font-size: 3.8rem;
    }

    .content-caroucel__item-info--text {
        font-size: 2.8rem;
        line-height: 35px;
    }

    .CarouselBoostrap .carousel-control-next,
    .CarouselBoostrap .carousel-control-prev {
        height: 6rem;
        width: 8rem;
    }

    .CarouselBoostrap .carousel-control-next-icon,
    .CarouselBoostrap .carousel-control-prev-icon {
        width: 3rem;
    }

    .content-caroucel_img-envolent {
        top: auto;
        bottom: -50px;
        left: 0;
        width: 20%;
    }
}

@media (max-width: 600px) {
    .content-caroucel__item-info .Subtitle {
        font-size: 2.8rem;
    }

    .content-caroucel__item-info--text {
        font-size: 1.8rem;
        line-height: 25px;
    }

    .content-benefits-title {
        font-size: 3rem;
    }

}

@media (max-width: 450px) {
    .content-caroucel__item-info--text {
        font-size: 1.3rem;
        line-height: 18px;
    }
}