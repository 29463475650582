.dynamics-intro {
    background-image: url('../../Assets/Images/Dynamics/Fondo_1.png');
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 0 0 4rem 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
}



.dynamics-intro-info {
    display: flex;
    align-items: center;
}

.dynamics-intro--title .Title {
    width: 44rem;
}

.dynamics-intro__p {
    width: 90%;
    margin-top: 5rem;
}

.dynamics-intro__p--text {
    font-size: 2rem;
    color: #1E9CD7;
    font-family: 'Bold';
    line-height: 29px;
}

.dynamics-intro__p--description {
    font-size: 3rem;
    color: #003F72;
    font-family: "Regular";
    line-height: 45px;
}

.dynamics-intro--img {
    width: 100%;
}

.dynamics-plus {
    padding: 6rem 0 0 0;
}

.dynamics-plus-info {
    display: flex;
    align-items: center;
}

.dynamics-plus__info {
    width: 85%;
}

.dynamics-plus--img {
    width: 80%;
}

.dynamics-plus__p--description {
    color: #707070;
    font-family: 'Regular';
    font-size: 4rem;
    line-height: 50px;
}

.dynamics-plusTwo {
    padding: 14rem 20px 0 20px;
}

.dynamics-tools {
    padding: 10rem 0 0 0;
}

.dynamics-tools-title {
    margin-bottom: 8rem;
}

.content-benefits-body {
    display: flex;
    justify-content: end;
}


.content-benefits-list {
    width: 90%;
}

.content-benefits {
    margin-top: 10rem;
}

.content-benefits-title {
    font-size: 4rem;
    font-family: 'Bold';
    color: #003F72;
}

.content-benefits {
    display: flex;
    justify-content: center;
}

.content-benefits-home {
    width: 85%;
}

.Benefits--title {
    color: #003F72;
    font-family: 'Bold';
    font-size: 2.5rem;
}

.Benefits--text {
    color: #707070;
    font-family: 'Regular';
    font-size: 2.5rem;
    line-height: 32px;
}




.dynamics-tools_more {
    padding: 10rem 0 0 0;
}

.dynamics-tools_more-info {
    display: flex;
    justify-content: center;
}

.dynamics-tools_more-col-text {
    display: flex;
    align-items: center;
}

.dynamics-tools_more-row {
    width: 80%;
    margin-top: 7rem !important;
}

.dynamics-tools_more--img {
    width: 100%;
}

.dynamics-tools_more--title .Title {
    font-family: 'Bold';
    font-size: 5rem;
    color: #8178E3;
}

.dynamics-tools_more__p--description {
    font-family: 'Medium';
    font-size: 2.2rem;
    color: #707070;
}

.dynamics-banner {
    padding: 10rem 0 0 0;
}

.dynamics-banner__info {
    position: relative;
}


.dynamics-banner__info-text {
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dynamics-banner__info--text {
    font-family: "Medium";
    font-size: 3rem;
    color: white;
    line-height: 39px;
    text-align: left;
    text-decoration: underline;
}

.dynamics-banner-content-info {
    width: 50%;
}


.dynamics-plustwo--subtitle {}

.dynamics-plustwo--text {
    color: #707070;
    font-family: 'Regular';
    font-size: 32px;

}

.dynamics-video {
    padding: 12rem 0 0 0;
}

.dynamics-video-content-info {
    text-align: center;
}

.dynamics-video__content-video {
    display: flex;
    justify-content: center;
}

.dynamics-video--video {
    // background-color: #ccc;
    width: 60%;
}

.dynamics-video--video video {
    width: 100%;
    height: 100%;
}

.dynamics-video-content--p {
    font-family: 'Regular';
    font-size: 3rem;
    color: #707070;
}

.dynamics-offer {
    padding: 12rem 0 0 0;
}


.dynamics-offer__content {
    margin-top: 15rem !important;
    margin-bottom: 15rem;
}

.dynamics-offer__info {
    display: flex;
    justify-content: flex-end;
}

.dynamics-offer__info-text {
    width: 80%;
}

.dynamics-offer--title {
    font-size: 6rem;
    color: #003F72;
    font-family: "Black";
}

.dynamics-offer-description {
    margin-top: 2rem;
}

.dynamics-offer--text {
    font-size: 2.5rem;
    color: #003F72;
    font-family: "Medium";
}

.dynamics-offer-button {
    margin-top: 5rem;
}




.btn-dynamics-offer {
    width: 35rem;
    border-radius: 35px !important;
    padding: 30px 0 0 0 !important;
    background: transparent linear-gradient(2deg, #2653C4 0%, #1E9CD7 100%) 0% 0% no-repeat padding-box;
}

.btn-dynamics-offer--ticket {
    width: 35rem;
    border-radius: 35px !important;
    padding: 30px 0 0 0 !important;
    background: transparent linear-gradient(90deg, #FFFFFF 0%, #F2F2FF 100%) 0% 0% no-repeat padding-box;
}


.line-h {
    margin-top: 4rem;
}

.line-h p {
    line-height: 45px;
}

.dynamics-offer__btn-number {
    font-size: 7rem;
    font-family: 'Black';
}

.dynamics-offer__btn-text {
    font-size: 3rem;
    font-family: 'Medium';
}

.btn-dynamics-offer .btn-hours-color {
    color: white;
}

.btn-dynamics-offer .btn-ticket-color {
    color: #003F72;
}

@media (max-width: 1200px) {
    .dynamics-banner__info--text {
        font-size: 2rem;
        color: white;
        line-height: 29px;
    }

    .dynamics-intro__p--description {
        font-size: 2rem;
        color: #9E9E9E;
        font-family: "Medium";
        line-height: 30px;
    }

    .dynamics-plus__p--description {
        color: #003F72;
        font-family: "Regular";
        font-size: 2rem;
        line-height: 31px;
    }

    .dynamics-plustwo--text {
        font-size: 23px;
    }

    .dynamics-plustwo-info .Subtitle {
        line-height: 35px;
    }

    .Benefits--text {
        color: #9E9E9E;
        font-family: "Light";
        font-size: 2rem;
        line-height: 27px;
    }
}

@media (max-width: 1480px) {
    .dynamics-plusTwo {
        padding-left: 2rem;
    }

}

@media (max-width: 992px),
screen and (orientation: portrait) {

    .dynamics-offer {
        width: 100%;
    }

    .dynamics-offer__title-text {
        text-align: center;
    }

    .dynamics-offer--title {
        font-size: 2rem;
    }

    .dynamics-offer-description {
        padding: 0 4rem;
        text-align: center;
    }

    .dynamics-offer--text {
        font-size: 1.5rem;
    }

    .dynamics-offer-button {
        text-align: center !important;
        margin-top: 3rem;
    }

    .dynamics-offer__btn-number {
        font-size: 4.5rem;
    }

    .dynamics-offer__btn-text {
        font-size: 2rem;
    }

    .cont-btns {
        text-align: center;
    }

    .btn-dynamics-offer {
        padding: 30px 0 15px 0 !important;
    }

    .btn-dynamics-offer p {
        margin: 0 !important;
    }

    .btn-dynamics-offer--ticket {
        padding: 30px 0 15px 0 !important;
    }

    .btn-dynamics-offer--ticket p {
        margin: 0 !important;
    }

    .dynamics-feature_1,
    .dynamics-feature_6_movile,
    .dynamics-feature_3,
    .dynamics-feature_8,
    .dynamics-feature_2_Movile {
        text-align: center;
    }

    .dynamics-feature_4 {
        justify-content: center;
    }

    .feature_3 {
        width: 100%;
    }

    .dynamics-offer__content {
        margin-top: 5rem !important;
        margin-bottom: 15rem;
    }

    .dynamics-feature_6_movile img,
    .dynamics-feature_4 img {
        height: 12rem;
    }

    .dynamics-feature_1 img,
    .dynamics-feature_8 img {
        height: 16rem;
    }

    .Benefits--title {
        font-family: "Medium";
        font-size: 2rem;
    }
}

@media (max-width: 769px) {
    .dynamics-intro {
        display: flex;
        flex-direction: column-reverse;
    }

    .dynamics-intro__content-img {
        display: flex;
        justify-content: center;
    }

    .dynamics-intro--img {
        width: 70%;
    }

    .dynamics-intro--title .Title {
        width: 100%;
    }

    .dynamics-intro__p--description {
        font-size: 2rem;
        color: #9E9E9E;
        font-family: "Medium";
        line-height: 30px;
    }

    .dynamics-intro__button .btn {
        width: 100%;
    }

    .dynamics-plus-img {
        display: flex;
        justify-content: center;
    }

    .dynamics-plus--img {
        width: 80%;
    }

    .dynamics-plus__info {
        width: 100%;
        padding: 0 2rem 0 4rem;
    }

    .dynamics-plus__p--description {
        color: #003F72;
        font-family: 'Medium';
        font-size: 2rem;
        line-height: 30px;
    }

    .dynamics-video--video {
        width: 90%;
    }

    .dynamics-video-content--p {
        font-family: 'Light';
        font-size: 2rem;
    }

    .dynamics-plusTwo {
        padding: 0rem 4rem 0 4rem;
    }

    .dynamics-plustwo--text {
        color: #707070;
        font-family: 'Light';
        font-size: 2rem;
    }

    .dynamics-plusTwo-img {
        display: none;
    }

    .dynamics-tools_more-img-content {
        display: flex;
        justify-content: center;
    }

    .dynamics-tools_more--img {
        width: 80% !important;
    }

    .dynamics-tools_more--title .Title {
        font-size: 3rem;
        color: #003F72;
    }

    .dynamics-tools_more__p--description {
        font-family: 'Light';
        font-size: 1.8rem;
        text-align: center;
    }

    .dynamics-banner {
        display: none !important;
        padding: 5rem 0 0 0;
    }

    .dynamics-offer__info-text {
        width: 100%;
    }

    .btn-dynamics-offer .btn-dynamics-offer,
    .btn-dynamics-offer .btn-dynamics-offer--ticket {
        width: 80%;
    }
}