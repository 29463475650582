.contact-intro__content-img {
  display: flex;
}

img.contact-intro--img--mobile {
  display: none;
}

.contact-intro-info {
  padding-left: 6rem;
}

img.contact-intro--img {
  width: 100%;
}

.Contact .col-12.mt-5.d-pc {
  padding-left: 7rem;
}

.contacto-oficinas .title__color--secundary {
  color: #1e9cd7;
  font-size: 2rem;
}

.contacto-oficinas .Subtitle.subtitle__color--secundary {
  font-size: 2rem;
  line-height: 25px;
  margin: 0;
  color: #7b7b7b;
}

.Contact .subtitle__color--secundary {
  color: #003f72;
  margin-top: 3rem;
  line-height: 4rem;
  font-size: 3rem;
  font-weight: 100 !important;
  font-family: "Light";
}

.Contact .subtitle__color--secundary {
  color: #003f72;
  margin-top: 3rem;
  margin-block-end: 3rem;
  line-height: 4rem;
  font-size: 2.9rem;
  font-weight: 100 !important;
  font-family: "Light";
}

.contact-intro-info {
  padding-left: 6rem;
}

.Title.title__color--secundary {
  z-index: 2;
}

.contacto-card {
  display: flex;
  margin: 6rem 0;
  padding-left: 6rem;
}

.contacto-card .form-contacto {
  background: transparent linear-gradient(90deg, #ffffff 0%, #f2f2ff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px rgba(1, 10, 33, 0.5490196078);
  width: 105rem;
  height: 50rem;
  padding: 2rem;
  border-radius: 1.5rem;
}

.form-control {

  font-size: 2rem !important;
}

select#formulario {
  width: 24rem;
  height: 4rem;
  font-size: 2rem;
}
select#Pais {
  width: 24rem;
  height: 4rem;
  font-size: 2rem;
}
.contacto-card .form-contacto .container.mt-5 row {
  width: 100%;
  display: flex;
}

.contacto-card .form-group-up {
  display: flex;
  gap: 14rem;
}

.contacto-card .form-contacto .col-md-6 {
  width: 100%;
}

.contacto-card form {
  font-size: 2.5rem;
  color: #003f72;
}

.contacto-card .form-control {
  background: #e0edfb;
  border-radius: 1rem;
  resize: none;
  height: 10rem;
}

.modal-content {
  font-size: 2rem !important;
}

.modal-content button.btn.button__prymary {
  height: 5rem!important;
  width: 16rem;
}

.contacto-card .form-group-up .form-control {
  height: 4rem;
  width: 39rem;
}

.name .form-control {
  width: 25rem;
}

.contacto-card .correo .form-control {
  width: 46rem;
}

.contacto-card .custom-color form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contacto-card .form-enviar {
  display: flex;
  gap: 35px;
  justify-content: flex-end;
}

.contacto-card .form-enviar .form-button-enviar button.btn.button__prymary {
  width: 24rem;
  height: 5rem !important;
}

.col-12.mt-5.d-pc-mobile {
  display: none;
}

@media (max-width: 768px) {
  .row.contact-intro {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .name .form-control {
    width: 25rem !important;
  }

  .contact-intro-info {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .contact-intro__content-img {
    width: 50%;
    padding: 0 !important;
  }

  .Contact span.color-movile {
    color: #aeaeae;
  }

  .col-12.mt-5.d-movile.contact-intro--title {
    margin: 0 !important;
    padding: 0 3rem !important;
  }

  .contact-intro--title .Title.title__color--secundary span {
    color: #003f72 !important;
    display: flex;
    justify-content: center;
  }

  .Contact span.color-movile-text {
    color: #c1c1c1;
    font-size: 1.4rem;
  }

  .col-12.mt-5.d-pc-mobile .contacto-oficinas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 5rem;
  }

  .Contact .Subtitle.subtitle__color--secundary {
    text-align: start;
  }

  .Contact .Title.title__color--secundary {
    font-size: 1.7rem !important;
  }

  .Contact .Subtitle.subtitle__color--secundary {
    font-size: 1.5rem !important;
  }

  .col-12.mt-5.d-pc-mobile {
    display: block;
  }

  .contacto-card .form-contacto {
    width: 32rem;
    height: 51rem;
  }

  .custom-color form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .contacto-card .form-group-up {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .contacto-card form {
    font-size: 1.5rem;
  }

  .contacto-card .form-enviar {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    flex-direction: column !important;
  }

  .contacto-card .correo .form-control {
    width: 26rem;
  }

  .contacto-card .contacto-card {
    display: flex;
    justify-content: center !important;
    padding-left: 0 !important;
  }

  .contact-intro-info {
    width: 50% !important;
  }

  .contact-intro__content-img {
    width: 50% !important;
  }

  .tittle-casos-de-exito {
    margin-top: 7rem !important;
  }

  .tittle-casos-de-exito.top_10 .Title.title__color--secundary {
    font-size: 3rem !important;
  }

  .about-trade.row.especialistas.top_10 .Title.title__color--secundary {
    font-size: 3rem !important;
  }

  .about-trade.row.especialistas.top_10 .Subtitle {
    margin-top: 8rem !important;
  }

  .contacto-card {
    padding: 0 !important;
    margin-top: 5rem !important;
    width: 100% !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
}